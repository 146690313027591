<script>
  import VLazyImage from "v-lazy-image"
  export default {
    components: {
      VLazyImage
    },
    props: ["movieCasts"],
    setup() {},
  };
</script>

<template>
  <div class="border-b border-gray-800">
    <div class="container px-4 py-16 mx-auto">
      <h2 class="text-4xl font-semibold">Cast</h2>
      <div
        class="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
      >
        <div class="mt-8" v-for="mvCasts in movieCasts" :key="mvCasts.id">
          <router-link :to="{ name: 'ActorsShow', params: { id: mvCasts.id } }">
            <v-lazy-image :src="`${mvCasts.thumb}`" src-placeholder="https://via.placeholder.com/300x450" width="300" height="450" class="transition duration-150 ease-in-out hover:opacity-75" alt="Cast"/>
          </router-link>
          <div class="mt-2">
            <a href="#" class="mt-2 text-lg hover:text-gray:300">{{
              mvCasts.tag
            }}</a>
            <div class="text-sm text-gray-400">{{ mvCasts.role }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>
